<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Billing Dashboard</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" class="btn custom-view-detail-btn" @click="billingDashboardPlanModalOpen()">
        Plan Details
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-12 billing-dashboard-scrollbar position-relative" style="height: calc(100vh - 141px)">
      <div v-if="!showdataloader">
        <div>
          <div class="
            content-header-section
            d-flex
            justify-content-between
            align-items-center
          ">
            <div>
              <!-- <span class="plan-header-label d-flex align-items-center">
                <div class="box-icon-inner d-flex justify-content-center me-2">
                  <img src="/assets/images/icon/plan-label-icon.svg" alt="" width="18" />
                </div>
                <div class="text-capitalize">{{ currentPlanInfo.plan_name }}</div>
              </span> -->
            </div>
            <div>
              <span class="box-subheader-label me-1">Activation Date :</span><span class="plan-header-label">
                {{ (currentPlanInfo) ? format_date(currentPlanInfo.plan_active_datetime) : 'N/A' }}</span>
            </div>
          </div>
          <div class="billing-dashboard-detail-outer">
            <div class="row g-3">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="service-plan-box-outer">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="plan-header-label">Main Balance</div>
                    <div class="chart-label">
                      {{
                        vueNumberFormat(billingDetails.balaceAmount, {
                          prefix: "₹ ",
                          decimal: ".",
                          thousand: ",",
                          precision: 2,
                          acceptNegative: true,
                        })
                      }}
                    </div>
                  </div>
                  <div class="
                    d-flex
                    justify-content-between
                    align-items-top
                    pt-0
                    mt-2
                  " v-if="mainfundboxcollapsed">
                    <div>
                      <div class="custom-form-group mb-0">
                        <input type="text" v-model="mainbalanceData.mainfundamount" class="form-control text-capitalize"
                          placeholder="Enter Amount" autocomplete="off" maxlength="8" @keypress="onlyNumber" />
                        <div class="custom-error" v-if="v$.mainbalanceData.mainfundamount.$error">
                          {{
                            v$.mainbalanceData.mainfundamount.$errors[0].$message
                          }}
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn add-fund-btn mt-1" @click="addMainFund()"
                      :disabled="showmainfundbtnloader" style="width: 90px;">
                      <span v-if="!showmainfundbtnloader">Add Fund</span>
                      <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                        v-if="showmainfundbtnloader"></div>
                    </button>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <button class="btn btn-link account-statement-link-btn" @click="redirectmainaccountstatement()">
                      Account Statement
                    </button>
                    <!-- <button class="btn btn-link account-statement-link-btn" @click="MainFundBoxCollapse()"
                      v-if="!mainfundboxcollapsed">
                      Add Fund
                    </button> -->
                    <button class="btn btn-link account-statement-link-btn" @click="MainFundModalOpen()">
                      Add Fund
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="service-plan-box-outer" style="height:76px;">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="plan-header-label">Minimum Balance Requirement is :</div>
                    <div class="chart-label">
                      {{
                        vueNumberFormat(billingDetails.minimumbalanceforactiveclient, {
                          prefix: "₹ ",
                          decimal: ".",
                          thousand: ",",
                          precision: 2,
                          acceptNegative: true,
                        })
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion" id="accordionCompanyBilling" v-if="companydetails">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingCompany">
              <button class="accordion-button custom-accordion-focus-out-btn" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseCompanyBilling" aria-expanded="false" aria-controls="collapseCompanyBilling">
                Billing Company Details
              </button>
            </h2>
            <div id="collapseCompanyBilling" class="accordion-collapse collapse" aria-labelledby="headingCompany"
              data-bs-parent="#accordionCompanyBilling">
              <div class="accordion-body">
                <div class="row g-2">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group">
                      <label class="box-subheader-label">Legal Name</label>
                      <div class="plan-header-label text-capitalize">
                        {{ companydetails.al3 ? companydetails.al3 : "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group">
                      <label class="box-subheader-label">GST No</label>
                      <div class="plan-header-label text-uppercase">
                        {{ companydetails.al6 ? companydetails.al6 : "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group">
                      <label class="box-subheader-label">Pan Card</label>
                      <div class="plan-header-label text-uppercase">
                        {{ companydetails.al5 ? companydetails.al5 : "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <div class="customer-label-group mb-0">
                      <label class="box-subheader-label">Address</label>
                      <div class="plan-header-label text-capitalize">
                        {{ companydetails.al7 ? companydetails.al7 : "N/A" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="customer-label-group mb-0">
                      <label class="box-subheader-label">Pincode</label>
                      <div class="plan-header-label text-capitalize">
                        {{ companydetails.al13 ? companydetails.al13 : "N/A" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!companydetails">
          <div class="
            content-header-section
            d-flex
            justify-content-between
            align-items-center
          ">
            <div>
              <span class="plan-header-label d-flex align-items-center">
                <div class="text-danger">Please add billing company details <button
                    class="btn btn-link account-statement-link-btn" @click="redirectcompanydetails()">
                    click here
                  </button></div>
              </span>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="
            content-header-section
            d-flex
            justify-content-start
            align-items-center
          ">
            <div>
              <span class="plan-header-label d-flex align-items-center">
                <div class="box-icon-inner d-flex justify-content-center me-2">
                  <img src="/assets/images/icon/feedback-day.svg" alt="" width="18" />
                </div>
                <div>
                  Below Usage is till {{ yesterday }} till 24:00 Hours.
                </div>
              </span>
            </div>
          </div>
          <div class="billing-dashboard-detail-outer mb-0">
            <div class="row g-3 d-flex justify-content-center">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/whatsapp-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">WhatsApp</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Initial Message</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidinitailwhatsappcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paidinitalwhatsappcustcharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                  <Divider />
                  <div class="
                  d-flex justify-content-between align-items-center
                  ">
                    <div class="service-type-name">Session Message</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidsessionwhatsappcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paidsessionwhatsappcustcharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/rcs-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">RCS</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Initial Message</div>
                    <div class="service-type-value">
                      0
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Session Message</div>
                    <div class="service-type-value">
                      0<span class="px-1">|</span>0
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/imassages-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">iMessage</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Initial Message</div>
                    <div class="service-type-value">
                      0
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Session Message</div>
                    <div class="service-type-value">
                      0<span class="px-1">|</span>0
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/sms-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">SMS</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Transactional SMS</div>
                    <div class="service-type-value">
                      {{ billingDetails.transactionalpaidsmscount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.transactionalsmscharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Promotional SMS</div>
                    <div class="service-type-value">
                      {{ billingDetails.promotionalpaidsmscount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(billingDetails.promotionalsmscharges, {
    prefix: "₹ ",
    decimal: ".",
    thousand: ",",
    precision: 2,
    acceptNegative: true,
  })
}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/calls-billing-icon.svg" alt="" width="15" />
                    </div>
                    <div class="plan-service-name">Calls</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Incoming Calls</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidincomingcallcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paidincallcharge,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Outgoing Calls</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidoutgoingcallcount }}<span class="px-1">|</span>{{
                        vueNumberFormat(billingDetails.paidoutcallcharge, {
                          prefix: "₹ ",
                          decimal: ".",
                          thousand: ",",
                          precision: 2,
                          acceptNegative: true,
                        })
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/email-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">Emails</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Transactional Emails</div>
                    <div class="service-type-value">
                      {{ billingDetails.transactionalpaidemailcount
                      }}<span class="px-1">|</span>{{ vueNumberFormat(
  billingDetails.transactionalemailcharges,
  {
    prefix: "₹ ",
    decimal: ".",
    thousand: ",",
    precision: 2,
    acceptNegative: true,
  }
)
}}
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Promotional Emails</div>
                    <div class="service-type-value">
                      {{ billingDetails.promotionalpaidemailcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.promotionalemailcharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/aadhar-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">Aadhar</div>
                  </div>
                  <div class="
                    box-points-outer
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="service-type-name">Aadhar V1</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidaadharv1count
                      }}<span class="px-1">|</span>{{ vueNumberFormat(
  billingDetails.paidaadharv1charges,
  {
    prefix: "₹ ",
    decimal: ".",
    thousand: ",",
    precision: 2,
    acceptNegative: true,
  }
)
}}
                    </div>
                  </div>
                  <Divider />
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Aadhar V2</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidaadharv2count
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paidaadharv2charges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/driving-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">Driving Licence</div>
                  </div>
                  <div class="box-points-outer d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Driving Licence Fetch</div>
                    <div class="service-type-value">
                      {{ billingDetails.paiddlcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paiddlcharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/rto-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">RTO</div>
                  </div>
                  <div class="box-points-outer d-flex justify-content-between align-items-center">
                    <div class="service-type-name">RTO</div>
                    <div class="service-type-value">
                      0<span class="px-1">|</span>0
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="plan-service-small-box-outer">
                  <div class="
                    box-icon
                    d-flex
                    justify-content-between
                    align-items-center
                  ">
                    <div class="box-icon-inner d-flex justify-content-center">
                      <img src="/assets/images/icon/passport-billing-icon.svg" alt="" width="24" />
                    </div>
                    <div class="plan-service-name">Passport</div>
                  </div>
                  <div class="box-points-outer d-flex justify-content-between align-items-center">
                    <div class="service-type-name">Passport Fetch</div>
                    <div class="service-type-value">
                      {{ billingDetails.paidpassportcount
                      }}<span class="px-1">|</span>{{
  vueNumberFormat(
    billingDetails.paidpassportcharges,
    {
      prefix: "₹ ",
      decimal: ".",
      thousand: ",",
      precision: 2,
      acceptNegative: true,
    }
  )
}}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="showdataloader" style="height: 430px">
        <div class="custom-modal-spinner-loader">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </div>
    </div>
  </div>
  <!-- plan detail table start here -->
  <div class="modal-mask" v-if="billingplandetailmodalstatus">
    <div class="
        modal-dialog modal-side-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize" id="staticBackdropLabel">
            {{ currentPlanInfo.plan_name }}
          </h5>
          <button type="button" class="btn-close" @click="billingDashboardPlanModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="position-relative billing-plan-table-scroll-outer">
            <table>
              <tr class="custom-header-outer">
                <td scope="col" style="width: 5%" class="text-center">#</td>
                <td scope="col" style="width: 65%">Component Name</td>
                <td scope="col" style="width: 30%">Charges</td>
              </tr>
              <tr v-for="(component, index) in currentPlanDetail" v-bind:key="index">
                <td style="width: 5%" class="text-center">{{ index + 1 }}</td>
                <td style="width: 65%">{{ component.component_name }}</td>
                <td style="width: 30%" class="text-end">
                  {{ component.additional_rate_for_client }}
                </td>
              </tr>
              <tr v-if="currentPlanDetail == null">
                <td colspan="3" style="width: 100%" class="text-center">No records found.</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- plan detail table end here -->
  <!-- add fund in account start here -->
  <div class="modal-mask" v-if="addfunctionmodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize" id="staticBackdropLabel">Add Fund
          </h5>
          <button type="button" class="btn-close" @click="MainFundModalClose()"></button>
        </div>
        <div class="modal-body pt-3">
          <div class="row g-2">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="account-details-box-outer addfundcardouter">
                <div class="addfunheader text-center">Account Details</div>
                <div class="service-type-value mt-3">Next Edge Civic Tech Private Limited</div>
                <Divider type="dashed" />
                <div class="d-flex justify-content-start align-items-center">
                  <div class="service-type-name">Account Number</div>
                  <div class="service-type-value" style="font-size: 16px;">: 345805001783</div>
                </div>
                <Divider type="dashed" />
                <div class="d-flex justify-content-start align-items-center">
                  <div class="service-type-name">IFSC Code</div>
                  <div class="service-type-value" style="font-size: 16px;">: ICIC0003458</div>
                </div>
                <Divider type="dashed" />
                <div class="d-flex justify-content-start align-items-center">
                  <div class="service-type-name">Branch</div>
                  <div class="service-type-value">: Balewadi, Pune, Maharashtra</div>
                </div>
                <!-- <div class="service-type-name w-100 mt-3 fst-italic text-center">Cheque to be drawn in the name of "Next
                  Edge Civic Tech Private Limited"</div> -->
              </div>
            </div>
          </div>
          <div class="billing-dashboard-detail-outer mt-3 mb-0">
            <div class="row">
              <div class="col-12">
                <div class="row mt-4">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Enter Amount<span class="text-danger">*</span></label>
                      <input type="text" v-model="fund.amountrtgs" id="addamountInput"
                        class="form-control text-capitalize" placeholder="Enter Amount" autocomplete="off" maxlength="8"
                        @keypress="onlypincodeAddFormat" />
                      <div class="custom-error" v-if="v$.fund.amountrtgs.$error">
                        {{
                          v$.fund.amountrtgs.$errors[0].$message
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Payment Mode<span class="text-danger">*</span></label>
                      <Multiselect v-model="fund.paymentmode" :options="paymentmodeList" :searchable="false" label="label"
                        placeholder="Select" class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                        :object="true" noOptionsText="No Result" />
                      <div class="custom-error" v-if="v$.fund.paymentmode.$error">
                        {{ v$.fund.paymentmode.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label for="formutrnumberInput" class="form-label">UTR No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="fund.utrnumber" class="form-control text-uppercase"
                        id="formutrnumberInput" placeholder="Enter UTR No." @keypress="UTRNumberValidation($event)"
                        autocomplete="off" maxlength="22" />
                      <div class="custom-error" v-if="v$.fund.utrnumber.$error">
                        {{ v$.fund.utrnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Date of Transaction<span class="text-danger">*</span></label>
                      <Calendar v-model="fund.paymentdate" :maxDate="new Date()" :showIcon="true"
                        class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                        :monthNavigator="true" :yearRange="'1950:' + new Date().getFullYear()" appendTo="body"
                        :yearNavigator="true">
                      </Calendar>
                      <div class="custom-error" v-if="v$.fund.paymentdate.$error">
                        {{ v$.fund.paymentdate.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Select Bank Name<span class="text-danger">*</span></label>
                      <Multiselect v-model="fund.bankname" :options="banknameList" :searchable="true" label="label"
                        placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                        :object="true" noOptionsText="No Result" />
                      <div class="custom-error" v-if="v$.fund.bankname.$error">
                        {{ v$.fund.bankname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Attach Screenshot<span class="text-danger">*</span></label>
                      <FileUpload mode="basic" :auto="true" accept="image/*" ref="voterprofileimg"
                        class="custom-upload-btn-ultima" v-model="fund.voterprofileimg"
                        v-on:change="handleUploadVoterProfile()" />
                      <div class="custom-error" v-if="v$.fund.voterprofileimg.$error">
                        {{ v$.fund.voterprofileimg.$errors[0].$message }}
                      </div>
                      <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                    </div>
                    <div class="text-primary" v-if="exitpreviewurl != null">{{ exitpreviewurl.name }}</div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                    <img :src="exitpreviewurl.objectURL" height="72" class="
                                  rounded
                                  bg-light
                                  me-auto
                                  d-block
                                " alt="" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12 text-center">
                    <button type="button" @click="confirmAddFundModalBtn()" class="btn modal-bulk-next-btn"
                      :disabled="showupdateloader">
                      <span v-if="!showupdateloader">Submit</span>
                      <div class="spinner-border custom-spinner-icon-loader-btn" role="status" v-if="showupdateloader">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add fund in account end here -->
</template>
<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      v$: useValidate(),
      servicenamelist: [
        { value: 1, label: "System Rental" },
        { value: 2, label: "WhatsApp Rental" },
      ],
      mainbalanceData: {
        mainfundamount: "",
      },
      advancebalanceData: {
        advancefundamount: "",
        servicename: "",
      },
      mainfundboxcollapsed: false,
      billingplandetailmodalstatus: false,
      billingDetails: "",
      currentPlanInfo: "",
      companydetails: "",
      currentPlanDetail: "",
      yesterday: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toLocaleDateString(),
      showmainfundbtnloader: false,
      showdataloader: false,
      addfunctionmodalstatus: false,
      fund: {
        amountrtgs: '',
        paymentmode: { label: "RTGS", value: 2 },
        utrnumber: '',
        paymentdate: null,
        bankname: '',
        voterprofileimg: '',
      },
      errorprofileimage: '',
      paymentmodeList: [
        { label: "RTGS", value: 2 },
      ],
      exitpreviewurl: null,
      showupdateloader: false,
      banknameList: [],

    };
  },
  validations() {
    return {
      mainbalanceData: {
        mainfundamount: {
          required: helpers.withMessage("Please enter amount", required),
        },
      },
      advancebalanceData: {
        advancefundamount: {
          required: helpers.withMessage("Please enter amount", required),
        },
        servicename: {
          required: helpers.withMessage("Please select service name", required),
        },
      },
      fund: {
        amountrtgs: {
          required: helpers.withMessage("Please enter amount", required),
          minLength: helpers.withMessage(
            "Please enter vaild amount",
            minLength(5)
          ),
        },
        paymentmode: {
          required: helpers.withMessage("Please select payment mode", required),
        },
        utrnumber: {
          required: helpers.withMessage("Please enter UTR No.", required),
          minLength: helpers.withMessage(
            "Please enter vaild UTR No.",
            minLength(22)
          ),
        },
        paymentdate: {
          required: helpers.withMessage("Please select date of transaction", required),
        },
        bankname: {
          required: helpers.withMessage("Please select bank name", required),
        },
        voterprofileimg: {
          required: helpers.withMessage("Please upload screenshot", required),
        },
      }
    };
  },
  mounted() {
    this.getBillingDashboardData();
    this.getbanklist();
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  methods: {
    getbanklist(e) {
      this.ApiService.getbanklist(e).then((data) => {
        if (data.status == 200) {
          this.banknameList = data.data;
        } else {
          this.banknameList = null;
        }
      });
    },
    confirmAddFundModalBtn() {
      this.v$.fund.$validate();
      var formData = new FormData();
      formData.append("bu4", this.fund.amountrtgs);
      formData.append("bu5", this.fund.paymentmode.value);
      if (this.fund.utrnumber) {
        formData.append("bu6", this.fund.utrnumber);
      }
      formData.append('bu13', moment(this.fund.paymentdate).format("YYYY-MM-DD"));
      formData.append("bu16", this.fund.bankname.value);
      formData.append("bu17", this.fund.bankname.label);
      if (this.fund.voterprofileimg) {
        formData.append("bu7", this.fund.voterprofileimg);
      }
      if (!this.v$.fund.$error && !this.errorprofileimage) {
        this.showupdateloader = true;
        this.ApiService.addFunds(formData).then((data) => {
          if (data.status == 200) {
            this.showupdateloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.addfunctionmodalstatus = false;
            this.errorprofileimage = '';
            this.exitpreviewurl = '';
            this.fund.voterprofileimg = '';
            this.getBillingDashboardData();
          } else {
            this.showupdateloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    handleUploadVoterProfile() {
      var file = this.$refs.voterprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
      if (!file) {
        this.errorprofileimage = 'Please upload screenshot';
      } else if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.fund.voterprofileimg = this.$refs.voterprofileimg.files[0];
        this.exitpreviewurl = this.fund.voterprofileimg;
      }
    },
    MainFundModalOpen() {
      this.addfunctionmodalstatus = true;
    },
    MainFundModalClose() {
      this.addfunctionmodalstatus = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
      this.fund.amountrtgs = '';
      this.fund.utrnumber = '';
      this.fund.paymentdate = null;
      this.fund.bankname = '';
      this.fund.voterprofileimg = '';
    },
    getBillingDashboardData() {
      this.showdataloader = true;
      this.ApiService.getBillingDashboardData().then((data) => {
        if (data.success == true) {
          this.billingDetails = data.data;
          this.currentPlanInfo = data.data.currentPlanInfo;
          this.companydetails = data.data.companydetails;
          this.showdataloader = false;
        } else {
          this.showdataloader = false;
          this.billingDetails = "";
          this.currentPlanInfo = "";
          this.companydetails = "";
        }
      });
    },
    getCurrentPlanDetails(e) {
      this.ApiService.getCurrentPlanDetails(e).then((data) => {
        if (data.success == true) {
          this.currentPlanDetail = data.data;
        }
      });
    },
    reloadDashboardBillData() {
      this.ApiService.getBillingDashboardData().then((data) => {
        if (data.success == true) {
          this.billingDetails = data.data;
        }
      });
    },
    addMainFund() {
      this.v$.mainbalanceData.$validate();
      let fields = {};
      fields["amount"] = this.mainbalanceData.mainfundamount;
      fields["type"] = 0;
      fields["billingCompanyId"] = this.companydetails.al1;
      if (!this.v$.mainbalanceData.$error) {
        this.showmainfundbtnloader = true;
        this.ApiService.addFunds(fields).then((data) => {
          if (data.success == true) {
            this.showmainfundbtnloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.mainfundboxcollapsed = false;
            this.mainbalanceData.mainfundamount = '';
            this.reloadDashboardBillData();
          } else {
            this.showmainfundbtnloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      }
    },
    MainFundBoxCollapse() {
      this.mainfundboxcollapsed = true;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    UTRNumberValidation(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    billingDashboardPlanModalOpen() {
      this.getCurrentPlanDetails({ planId: this.currentPlanInfo.plan_id });
      this.billingplandetailmodalstatus = true;
    },

    billingDashboardPlanModalClose() {
      this.billingplandetailmodalstatus = false;
    },
    redirectmainaccountstatement() {
      this.$router.push("/admin/billing/statement");
    },
    redirectcompanydetails() {
      this.$router.push("/admin/billing/companydetails");
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_date_d(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      } else {
        return 'N/A';
      }
    },
    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addamountInput = document.getElementById("addamountInput");
      if ((addamountInput.value == '' || addamountInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.billing-dashboard-detail-outer {
  padding: 16px;
  margin-bottom: 20px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  position: relative;
}

.billing-dashboard-detail-outer .custom-col-3 {
  width: 20%;
}

.content-header-section {
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.plan-header-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.box-subheader-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  user-select: none;
}

.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px;
}

.account-details-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 16px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .box-subheader-label {
  margin-bottom: 6px;
}

.service-plan-box-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.add-fund-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  font-family: "AcuminPro-Regular";
  height: 34px;
}

.add-fund-btn.btn:hover,
.account-statement-link-btn:hover {
  box-shadow: none !important;
}

.account-statement-link-btn {
  padding: 0;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #5266e5;
  line-height: 12px;
  text-decoration: none;
}

.box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  user-select: none;
  pointer-events: none;
}

.custom-accordion-focus-out-btn {
  user-select: none;
}

.custom-accordion-focus-out-btn:focus {
  box-shadow: none;
}

.plan-service-small-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  user-select: none;
}

.plan-service-small-box-outer:hover {
  -moz-transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border: 1px solid #b7bfc9;
}

.plan-service-small-box-outer .box-icon .box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  user-select: none;
  pointer-events: none;
}

.plan-service-small-box-outer .box-label {
  letter-spacing: 0.26px;
  color: #4a5463;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  line-height: 13px;
  padding-top: 16px;
  user-select: none;
}

.plan-service-small-box-outer .box-points-outer {
  user-select: none;
  padding-top: 16px;
}

.plan-service-small-box-outer .service-type-name {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}

.plan-service-small-box-outer .service-type-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 14px;
}

.plan-service-small-box-outer .plan-service-name {
  font-family: "AcuminPro-Bold";
  font-size: 15px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 15px;
}

.billing-dashboard-scrollbar {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.billing-dashboard-scrollbar::-webkit-scrollbar,
.billing-plan-table-scroll-outer::-webkit-scrollbar {
  display: block;
  width: 0.3em;
  height: 0.3em;
}

.billing-dashboard-scrollbar::-webkit-scrollbar-thumb,
.billing-plan-table-scroll-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.billing-plan-table-scroll-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 513px;
}

.billing-plan-table-scroll-outer {
  overflow: hidden;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 105px) !important;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
  user-select: none;
}

.billing-plan-table-scroll-outer table {
  width: 100%;
}

.billing-plan-table-scroll-outer .custom-header-outer {
  position: sticky;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #e0e5ed;
  color: #4a5463;
  background: #f2f4fb;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  line-height: 13px;
  font-weight: 400;
  user-select: none;
}

.billing-plan-table-scroll-outer td {
  padding: 8px;
  padding: 10px 6px;
  border: 1px solid #e0e5ed;
}

.addfundcardouter .addfunheader {
  font-family: "AcuminPro-Bold";
  font-size: 16px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 19px;
}

.addfundcardouter .service-type-name {
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 20px;
  width: 130px;
}

.addfundcardouter .service-type-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}
</style>
